var $filterForm = $('form.js-catalog-filter');
if ($filterForm) {
    var $submitButton = $filterForm.find('.filter__apply'),
        $inputs = $filterForm.find('input'),
        timeout = null;
    // $inputs.on('change', function(e) {
    // e.preventDefault();

    // if (timeout) {
    //     clearTimeout(timeout);
    // }
    // timeout = setTimeout(function() {
    // var name = $(e.target)
    //     .closest('.filter-prod__item')
    //     .data('name');
    // $.post(window.location.pathname + '?' + $filterForm.serialize(), function(resp) {
    // for (var respKey in resp) {
    //     if (respKey !== name) {
    //         $filterForm.find('[data-name=' + respKey + '] input').map(function(i, input) {
    //             if (!input.checked) {
    //                 input.disabled = resp[respKey].indexOf(input.value) === -1;
    //             }
    //         });
    //     }
    // }
    // });
    // }, 800);
    // });
    $filterForm.on('submit', function(e) {
        e.preventDefault();
        let params = new URLSearchParams(document.location.search);
        $submitButton.addClass('btn--disabled');
        const regexPage = /page\d?\//
        let url = (window.location.pathname).replace(regexPage, '');
        const querySearch = params.get("q") ? '?q=' + params.get("q") : '';
        const queryCategory = params.get("category") ? '&category=' + params.get("category") : '';
        window.location.href = url + (querySearch ? querySearch + '&f=true&' + $(e.target).serialize() + queryCategory : '?f=true&' + $(e.target).serialize() + queryCategory);



        // window.location.href = url + (querySearch ? querySearch + queryCategory + '&f=true&' + $(e.target).serialize() : '?f=true&' + $(e.target).serialize());
    });

    $filterForm.find('.filter__reset').on('click', function(e) {
        e.preventDefault();
        window.location = this.dataset.link;
    });

    $('.js-catalog-sort, .js-sort')
        .find('select')
        .on('change', function(e) {
            if (this.value) {
                window.location = this.value;
            }
            // this.submit();
        });

    $('.js-catalog_check').find('input').on('change', function (e) {

        if (this.value) {
            window.location = this.value;
            console.log(this.value);
        }

    });

}
