//корзина товаров
//
//require:
//  meta.js
//  json2.js

function UserCart() {

    //singleton
    if (typeof UserCart.instance === "object") {
        return UserCart.instance;
    }

    var self = this;

    //данные корзины
    self.items = [];

    //адрес корзины
    //заполнять эту штуку надо в шаблоне view
    self.orderActionsUri = "/order/ajax_cart/";
    //макс уникальных позиций
    self.maxItemsCount = 99;
    //макс элементов в позиции
    self.maxItemQty = 999;

    //уникальные поля для товара
    var itemPrimaryFields = ["id","choice"],
        //второстепенные поля
        itemOptionalFields = ["price", "oldprice"];


    // тестирование элемента
    function validateItem(item) {
        //поиск уникальных полей, как правило это id
        for (var i = 0, max = UserCart.itemPrimaryFields; i < max; i++) {
            if (!item.hasOwnProperty(UserCart.itemPrimaryFields[i])) {
                return false;
            }
        }

        item.id = parseInt(item.id, 10);
        if (!item.hasOwnProperty("qty")) {
            item.qty = 1;
        } else {
            item.qty = parseInt(item.qty, 10);
        }

        if (!(item.id) || (!item.qty)) {
            return false;
        }

        return item;
    }


    var unsetPopupCart = $('.unset-popup--cart');
    var timer;

    self.hideUnsetPopupCart = function(){
        unsetPopupCart.removeClass('active');
    }
    self.removeClassAnimationUnsetPopupCart = function(){
        unsetPopupCart.removeClass('unset-popup--jitter')
    }

    self.showPopupAddProduct = function(text) {
        if (document.querySelector('.header--active')){
            unsetPopupCart.css("top", "20px")
        }
        unsetPopupCart.find('.js-unset-popup-cart-text').text(text)
        if (unsetPopupCart.hasClass('active')){
            clearTimeout(timer)
            timer = setTimeout(self.hideUnsetPopupCart, 1500);
            unsetPopupCart.addClass('unset-popup--jitter')
            setTimeout(self.removeClassAnimationUnsetPopupCart, 200)
        }else {
            unsetPopupCart.addClass('active')
            clearTimeout(timer);
            timer = setTimeout(self.hideUnsetPopupCart, 1500);
        }
    }

    function saveCart(actionType, data, callback) {


        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: self.orderActionsUri + actionType + "/",
            data: {
                data: data
            },
            success: function (items) {
                const allowedRemainingProducts = ['add', 'update']
                self.items = items;

                if (allowedRemainingProducts.includes(actionType)) {
                    var product = self.items[getItemIndex(data)];
                    // if (product.over_limit) {
                    //     showPopupAddProduct('Количество выбранного товара превышает количество на складе')
                    // } else {
                    //     showPopupAddProduct('Товар успешно добавлен в корзину')
                    // }
                }

                if (typeof callback === "function") {
                    callback(product);
                }
            }
        });
    }

    //вернуть указатель на элемент массива
    function getItemIndex(newItem) {
        var currentItemIndex = null,
            isCurrentItem;

        for (var itemIndex = 0, maxItems = self.items.length; itemIndex < maxItems; itemIndex++) {
            isCurrentItem = true;
            if (newItem.choice === self.items[itemIndex].choice && newItem.id == self.items[itemIndex].id) {
                currentItemIndex = itemIndex;
            }

        }


        return currentItemIndex;
    }

    self.getItem = function (id) {
        var index = getItemIndex({id: id});
        if (index !== null) {
            return self.items[index];
        }
        return null;
    };


    //загрузка корзины
    self.init = function () {
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: self.orderActionsUri,
            success: function (cartData) {
                if (cartData) {
                    var validItem;

                    for (var i = 0, max = cartData.length; i < max; i++) {
                        validItem = validateItem(cartData[i]);

                        if (validItem) {
                            self.items.push(validItem);
                        }
                    }

                    if (typeof self.onInitCallback === 'function') {
                        self.onInitCallback();
                    }
                }
            }
        });
    };

    self.onInitCallback = null;

    /**
     * Добавление товара в корзину
     * @param {object} newItem новый объект данных
     * @param {bool} isSet режим добавления,
     * если не указан - добавление, иначе - выставление нового значения
     * @param callback
     */
    self.putItem = function (newItem, isSet, callback) {
        newItem = validateItem(newItem);
        if (newItem) {
            var currentItemIndex = getItemIndex(newItem), currentItem;
            if (currentItemIndex != null) {
                currentItem = self.items[currentItemIndex];

            }
            if (isSet && currentItem) {

                for (var fieldIndex = 0, maxFields = itemOptionalFields.length; fieldIndex < maxFields; fieldIndex++) {
                    if (newItem.hasOwnProperty(itemOptionalFields[fieldIndex])) {
                        if (newItem.hasOwnProperty(itemOptionalFields[fieldIndex])) {
                            currentItem[itemOptionalFields[fieldIndex]] = newItem[itemOptionalFields[fieldIndex]];
                        }
                    }
                }

                if (newItem.qty > self.maxItemQty) {
                    newItem.qty = self.maxItemQty;
                }

                currentItem.qty = newItem.qty;

                saveCart("update", currentItem, callback);

            } else {

                saveCart("add", newItem, callback);
            }
        }

    };

    //добавление нескольких товаров
    self.putItems = function (items, callback) {

        var newItem,
            currentItem,
            newItems = [],
            currentItemIndex;

        for (var i = 0, max = items.length; i < max; i++) {
            currentItem = null;

            newItem = validateItem(items[i]);
            currentItemIndex = getItemIndex(newItem);

            if (currentItemIndex != null) {
                currentItem = self.items[currentItemIndex];
            }

            if (currentItem) {
                for (var fieldIndex = 0, maxFields = itemOptionalFields.length; fieldIndex < maxFields; fieldIndex++) {
                    if (newItem.hasOwnProperty(itemOptionalFields[fieldIndex])) {
                        currentItem[itemOptionalFields[fieldIndex]] = newItem[itemOptionalFields[fieldIndex]];
                    }
                }

                if ((currentItem.qty + newItem.qty) > self.maxItemQty) {
                    currentItem.qty = self.maxItemQty;
                } else {
                    currentItem.qty += newItem.qty;
                }

                newItems.push(currentItem);
            } else {
                if (self.items.length < self.maxItemsCount) {
                    self.items.push(newItem);

                    newItems.push(newItem);
                }
            }
        }

        saveCart("update_many", newItems, callback);
    };

    //удаление товара
    self.removeItem = function (item, callback) {
        var currentItemIndex = getItemIndex(item);

        if (currentItemIndex !== null) {
            self.items.splice(currentItemIndex, 1);
            saveCart("remove", item, callback);
        }
    };

    self.bonuscart = function (callback) {
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: self.orderActionsUri + "bonuscart/"
        }).done(function (response) {
            if (typeof callback === 'function') {
                callback(response);
            }
        }).fail(function (error) {
            alert(error);
        });
    };

    //удаление всего
    self.removeAll = function (callback) {
        saveCart("clear", null, callback);
    };

    UserCart.instance = self;
    return self;
}

var Cart = new UserCart();
$(function () {
    Cart.init();
});
